// Handle errors
import '../shared/errors'

// Import jquery
window.$ = window.jQuery = require('jquery')

// Add Rails CSRF token to every XHR
window.$.ajaxSetup({
  beforeSend: function(xhr) { xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')) }
})

import Rails from 'rails-ujs'
Rails.start()

// Import Bootstrap plugins
require('bootstrap-sass/assets/javascripts/bootstrap/affix')

import 'bootstrap';
import 'popper.js';

const $document = $(document);
const $window = $(window);

// PostHog
import posthog from 'posthog-js'
window.Posthog = posthog;

import IntlInput from '../shared/IntlInput'
import CheckStatus from './CheckStatus'
import Dev from '../store/dev'

window.IntlInput = IntlInput
window.CheckStatus = CheckStatus
window.Dev = Dev

function drawChart(el, progress, color) {
  const circle = el.querySelector('.circle');
  const half = el.querySelectorAll('.half');
  const left = el.querySelector('.left');
  const right = el.querySelector('.right');

  half.forEach(h => (h.style.borderColor = color));
  left.style.transform = `rotate(${progress * 3.6}deg`;

  if (progress <= 50) {
    right.style.display = 'none';
  } else {
    circle.style.clip = 'rect(auto, auto, auto, auto)';
    right.style.transform = 'rotate(180deg)';
  }
}

$( document ).ready(function() {
  $('.intercom--open').on('click', function() {
    Intercom('show');
  });

  $('.btn-nav').on('click', function() {
    $('html').toggleClass('show-nav');
  });

  document.querySelectorAll('.radial-progress').forEach(el => {
    drawChart(
      el,
      el.getAttribute('data-progress'),
      el.getAttribute('data-color'),
    );
  });

  new IntlInput($('.phone-input__country')).activate()
});
